// Customer Insights
interface MSCIWindow extends Window {
  MSCI?: {
    setUser: (user: { authId: string }) => void;
  };
}

// When customer provides the email we can personalize the experience
export const authenticateUser = (email: string) => {
  const win = window as MSCIWindow;
  if (win && win.MSCI) win.MSCI.setUser({ authId: email });
};
